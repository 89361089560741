import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Navigation, HorizontalBar, VerticalBar } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "header"
    }}>{`Header`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Navigation from '@mfrm/mfcl/Navigation'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p><strong parentName="p">{` This component is currently for demo/proof-of-concept purposes. `}</strong>{`
The Navigation component is a flexible, lightweight component that can optionally extend standard navbar functionalities - links, forms, CTA buttons, etc.`}</p>
    <h2 {...{
      "id": "navigation-properties"
    }}>{`Navigation Properties`}</h2>
    <Props of={Navigation} mdxType="Props" />
    <Playground __position={1} __code={'<Navigation />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Navigation,
      HorizontalBar,
      VerticalBar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Navigation mdxType="Navigation" />
    </Playground>
    <h2 {...{
      "id": "horizontal-bar-properties"
    }}>{`Horizontal Bar Properties`}</h2>
    <Props of={HorizontalBar} mdxType="Props" />
    <h2 {...{
      "id": "horizontal-bar"
    }}>{`Horizontal Bar`}</h2>
    <Playground __position={3} __code={'<HorizontalBar />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Navigation,
      HorizontalBar,
      VerticalBar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HorizontalBar mdxType="HorizontalBar" />
    </Playground>
    <h2 {...{
      "id": "vertical-bar-properties"
    }}>{`Vertical Bar Properties`}</h2>
    <Props of={VerticalBar} mdxType="Props" />
    <h2 {...{
      "id": "vertical-bar"
    }}>{`Vertical Bar`}</h2>
    <Playground __position={5} __code={'<VerticalBar\n  height=\"600px\"\n  activeNavOption=\"Matcher\"\n  avatarDetail={{ name: \'Bucky Barnes\', title: \'#017021\' }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Navigation,
      HorizontalBar,
      VerticalBar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <VerticalBar height="600px" activeNavOption="Matcher" avatarDetail={{
        name: 'Bucky Barnes',
        title: '#017021'
      }} mdxType="VerticalBar" />
    </Playground>
    <h2 {...{
      "id": "vertical-bar-using-first-and-last-name-for-display-name"
    }}>{`Vertical Bar using First and Last Name for display name`}</h2>
    <Playground __position={6} __code={'<VerticalBar\n  height=\"600px\"\n  activeNavOption=\"Matcher\"\n  avatarDetail={{\n    name: \'Bucky Barnes\',\n    title: \'#017021\',\n    useFirstNameForDisplay: false,\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Navigation,
      HorizontalBar,
      VerticalBar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <VerticalBar height="600px" activeNavOption="Matcher" avatarDetail={{
        name: 'Bucky Barnes',
        title: '#017021',
        useFirstNameForDisplay: false
      }} mdxType="VerticalBar" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      